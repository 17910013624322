<!-- 简历库 -->
<template>
  <div class="wrap">
    <el-table
      :data="tableData"
      stripe
      style="width: 100%; min-height: 70vh"
      @row-click="goDetail"
      v-loading="loading"
    >
      <!-- <el-table-column label="头像" width="100" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.avatar" alt />
        </template>
      </el-table-column>-->
      <el-table-column
        prop="name"
        label="姓名"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="gender"
        label="性别"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="age"
        label="年龄"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="联系方式"
        width="110"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="resume_education_experience_list[0].education_background"
        label="学历"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column label="期望薪资" align="center">
        <template
          v-if="
            scope.row.resume_expect_position_list &&
            scope.row.resume_expect_position_list.length > 0
          "
          slot-scope="scope"
          >{{ scope.row.resume_expect_position_list[0].salary_min }}-{{
            scope.row.resume_expect_position_list[0].salary_max
          }}元</template
        >
      </el-table-column>
      <el-table-column
        prop="expect_position_name_str"
        label="期望岗位"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="expect_place_name"
        label="期望地区"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="下载日期"
        align="center"
      ></el-table-column>

      <el-table-column label="聊一聊" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.elite_id"
            class="chat"
            @click.stop="onChatDetail(scope.row)"
            src="./../../assets/images/icon_img/chat.png"
          />
        </template>
      </el-table-column>
      <el-table-column label="复制微信号" align="center">
        <template slot-scope="scope">
          <img
            
            class="chat"
            @click.stop="onFuzhi(scope.row)"
            src="./../../assets/images/icon_img/weixin.png"
          />
        </template>
      </el-table-column>

      <el-table-column label="简历状态" align="center">
        <template slot-scope="scope">
          <el-tag effect="dark" :type="typecolor[scope.row.status]">{{
            typetext[scope.row.status]
          }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next"
        :total="pageTotal"
        @current-change="pageNowHandler"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getResumeBankData } from "@/api/resume";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      tableData: [],
      //标签类型
      typecolor: ["", "danger", "warning", "success"],
      typetext: ["未标记", "不合格", "未接通", "可面试"],
      //页数
      pageTotal: null,
      pageNow: 1,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 复制手机号到剪切板
    onFuzhi(item) {
      //获取数据跳
      //console.log(item.phone);

      const save = function (e) {
        e.clipboardData.setData("text/plain", item.phone);
        e.preventDefault(); // 阻止默认行为
      };
      document.addEventListener("copy", save); // 添加一个copy事件
      document.execCommand("copy"); // 执行copy方法
      this.$message({ message: "复制微信号成功", type: "success" });
    },

    // 进入聊天详情页面
    onChatDetail(item) {
      //获取数据跳转到聊天页面
      console.log(item);
      this.$router.push({
        path: "/chatdetail",
        query: {
          elite_id: item.elite_id,
          jd_id: 0,
        },
      });
    },

    goDetail(row) {

      console.log(row);
      if (row.type =='2'){
          this.$router.push({
          path: "/resumebankdetails",
          query: { id: row.resume_id },
        });
      }else{
        this.$router.push({
          path: "/leichangDetails",
          query: { elite_id: row.elite_id },
        });
      }
    
    },
    pageNowHandler(value) {
      if (this.pageNow != value) {
        console.log(11);
        this.loading = true;
        this.pageNow = value;
        getResumeBankData({ page: this.pageNow }).then((res) => {
          if (res.code === 0) {
            this.loading = false;
            this.tableData = res.data.resume_list;
            this.pageTotal = res.data.count;
          }
        });
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    getResumeBankData({ page: 1 }).then((res) => {
      if (res.code === 0) {
        console.log(res);
        this.loading = false;
        this.tableData = res.data.resume_list;
        this.pageTotal = res.data.count;
      }
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style  scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  min-width: 1100px;
  padding-bottom: 0;
}
img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
}
// 分页
.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  text-align: center;
}

.chat {
  width: 30px;
  height: 30px;
}
</style>